import React from "react"
import Saari from "../../images/saari.svg"
import Mokki from "../../images/mokki.svg"
import Lammas from "../../images/lammas.svg"
import { FormattedHTMLMessage } from "react-intl"

const Activities = () => {
  return (
    <section className='section is-large has-text-centered has-text-grey activities'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            <Saari className='icon' />
            <div className='column'>
              <FormattedHTMLMessage id='Home_Island' />
            </div>
          </div>
          <div className='column'>
            <Mokki className='icon' />
            <div className='column'>
              <FormattedHTMLMessage id='Home_Cottage' />
            </div>
          </div>
          <div className='column'>
            <Lammas className='icon' />
            <div className='column'>
              <FormattedHTMLMessage id='Home_Lamb' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Activities
