import React from "react"

import withLayout from "../layout"
import Hero from "../components/Hero"
import Activities from "../components/Activities"

const IndexPage = () => (
  <>
    <Hero />
    <Activities />
  </>
)

const customProps = {
  localeKey: "Home",
}

export default withLayout(customProps)(IndexPage)
