import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedHTMLMessage } from "react-intl"
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom"
import { Swiper, Autoplay } from "swiper/js/swiper.esm"
import Img from "gatsby-image"
import "swiper/css/swiper.css"

const Hero = () => {
  const { allContentfulMainCarousel } = useStaticQuery(
    graphql`
      query {
        allContentfulMainCarousel(filter: { node_locale: { eq: "fi" } }) {
          edges {
            node {
              images {
                localFile {
                  name
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const params = {
    Swiper,
    modules: [Autoplay],
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    preloadImages: false,
  }

  return (
    <section className='section hero'>
      <div className='swiper'>
        <ReactIdSwiperCustom {...params}>
          {allContentfulMainCarousel.edges[0].node.images.map((value, i) => (
            <Img
              className='carouselImg'
              fluid={value.localFile.childImageSharp.fluid}
              key={i}
              alt={value.localFile.name.replace(/-/g, " ").substring(2)}
            />
          ))}
        </ReactIdSwiperCustom>
      </div>
      <div className='container has-text-centered has-text-white is-size-5 mt-50'>
        <FormattedHTMLMessage id='Home_Introduction' />
      </div>
      <div className='overlay'></div>
    </section>
  )
}

export default Hero
